import { IdealTimerComponent } from './../modules/idealTimer/idealtimer.component';
import { IdleTimerService } from './_services/idletimerservice';
import { PrivateComponent } from './layouts/private/private.component';
import { Component, OnDestroy, OnInit, Inject, ViewChild,HostListener,ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
import { Subscription } from 'rxjs';
import { filter, first } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
declare function LoadInservioWebScript(): any;
//import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as momentJs from 'moment';
import { data } from "jquery";
import * as moment from "moment";
import { LocalStorageService } from 'ngx-localstorage';
import * as ConfigKey from './../common/common';
import { AuthGuard } from './_guards';
import * as APIMethodConfig from './../common/apimethod';
import { RequestService } from 'inservioweb/services/requestservice';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';



@Component({
  selector: 'inservio-app-component',
  templateUrl: './inservioappcomponent.html',
  styles: [
    `.modal{background: rgba(0,0,0, .5)}`
  ]
})
//implements OnInit, OnDestroy
export class InservioAppComponent {
  subscription: Subscription = new Subscription;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  display = "none";
  displayQR= 'none';
  displayOrderUID: string = 'none';
  orderUidInput: string = '';
  clearOrderUID: string = '';
  clearqr='';
  qrpopupwindowshow=false;
  orderuidpopupwindow=false;
  interval:any;
  searchName: string = '';
  isQuickSearchModalActive: boolean = false;
  patientsList: any[] = [];
  selectedIndex: number = -1;
  activeRowIndex: number = -1;
  @ViewChild('qrcode') searchElement: ElementRef;
  @ViewChild('orderUIDInput') searchElement2: ElementRef;
  @ViewChild('quickSearchModalGlobal', { static: false }) quickSearchModalGlobal: ElementRef;
  @ViewChild('searchNameFormGlobal') searchNameFormGlobal: NgForm;
  
  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: any,
    private idle: Idle, private keepalive: Keepalive,
    private storageService: LocalStorageService, private AuthGuard: AuthGuard,
    private idleService: IdleTimerService,
    private changeDetectorRef: ChangeDetectorRef,
    private requestService: RequestService,
    private toastr: ToastrService,
    private renderer: Renderer2  
  ) {
  
    var currurl = window.location.href;
    let dashboadlogin = localStorage.getItem("dashboadlogin");
    let isDashboardLogin = Boolean(JSON.parse(dashboadlogin));
    if (currurl.includes('updatepassword') || currurl.includes('forgetpassword')) {
      
    } else {
      if (!isDashboardLogin) {
        this.logout();
      }
    }


    idle.setIdle(1800); // 30 minutes 
    idle.setTimeout(7200); // 2 Hours
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "I'm back, please"
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.router.navigate(['/login']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      var Key = this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY);
      const ToeknData = this.AuthGuard.GetTokenDetail();
      if (ToeknData != null) {
        document.getElementById("openModalButton").click();
      }
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      var hours = 0;
      var minutes = 0;
      var seconds = 0;
      var xstate = 0;
      if (countdown > 1800) {
        hours = Math.floor(countdown / 3600);
        var remain = countdown % 3600;
        minutes = Math.floor(remain / 60);
        var secremain = remain % 60;
        seconds = secremain;
        xstate = 2;
      }
      else if (countdown > 60) {
        minutes = Math.floor(countdown / 60);
        var secremain = countdown % 60;
        seconds = secremain;
        xstate = 1;
      }
      else {
        seconds = countdown;
      }
      if (xstate == 0) {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
      }
      else if (xstate == 1) {
        this.idleState = 'You will time out in ' + minutes + ' minutes & ' + seconds + ' seconds!';
      }
      else if (xstate == 2) {
        this.idleState = 'You will time out in ' + hours + ' hours, ' + minutes + ' minutes & ' + seconds + ' seconds!';
      }
      this.changeDetectorRef.markForCheck();
    });

    keepalive.interval(1800);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.idleService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
        this.reset();
      } else {
        idle.stop();
        this.reset();
      }
    })
    this.reset();

  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'F2'){
      this.openModalQR();
    }
    if (event.key === 'F1'){
      this.openModalOrderUID();
    }
    if (event.key === 'Escape' && this.qrpopupwindowshow === true){
      this.onCloseHandledQR();
    }
    if (event.key === 'Escape' && this.orderuidpopupwindow === true){
      this.onCloseHandledOrderUID();
    }
  }


  openModalOrderUID() {
    this.displayOrderUID = "block";
    this.orderuidpopupwindow = true;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.searchElement2.nativeElement.focus();
    },0);

  }

  onOrderUIDInputChange(event: any) {
    if (event.target.value.length === 6) {
      this.onFindOrder();
    }
  }

  onCloseHandledOrderUID() {
    this.displayOrderUID = "none";
    this.orderUidInput = '';
  }

  onFindOrder() {
    if (this.searchElement2.nativeElement.value && this.searchElement2.nativeElement.value.length==6){
      this.FindOrder(this.searchElement2.nativeElement.value);
    } else {
      this.toastr.error('Please enter a valid 6-digit Order UID.', 'Error');
    }
  }

  FindOrder(orderUid: string) {
    const apiUrl = `${APIMethodConfig.InservioAPIMethod.Orders.GetNewOrderByUID}/${orderUid}`;
    this.requestService.HttpGet<any>(apiUrl, null)
      .subscribe(
        (response: any) => {
          if (response && response.status && response.entity) {
            // If a valid response is received, show a success message
            const orderReferenceId = response.entity.orderReferenceId || orderUid;
            this.toastr.success(`Order ${orderReferenceId} found successfully.`, 'Success');
            
            // Get the OrderDetailId and navigate to the details page
            const orderDetailId = response.entity.orderDetailId;
            if (orderDetailId) {
              this.clearOrderUID = 'Redirecting to..';      
              this.router.navigate([`/orders/details/${orderDetailId}`]).then(() => {
                setTimeout(() => {
                  this.clearOrderUID = '';
                  this.onCloseHandledOrderUID();
                  this.searchElement2.nativeElement.value = null;
                  window.location.reload();
                }, 2000);
              });
            } else {
              this.toastr.error('Order detail ID not found in the response.', 'Error');
            }
          } else if (response && !response.status) {
            // If the response indicates a failure, show an error message with the returnMessage
            const returnMessage = response.returnMessage && response.returnMessage.length > 0
              ? response.returnMessage.join(', ')
              : 'The order was not found or an error occurred.';
            
            this.toastr.error(returnMessage, 'Error');
          } else {
            // Handle unexpected response formats
            this.toastr.error('Unexpected response format.', 'Error');
          }
        },
        error => {
          // Handle HTTP errors and include the returnMessage if available
          const returnMessage = error.error?.returnMessage && error.error.returnMessage.length > 0
            ? error.error.returnMessage.join(', ')
            : 'There was an error retrieving the order. Please try again later.';
          
          this.toastr.error(returnMessage, 'Error');
        }
      );
  }
  
  

  onScanQR(qr){        
    console.log('onScanQR change: '+qr);
     this.clearqr = 'Redirecting to..';        
     //console.log("/orders/details/"+qr)
     
     
     this.router.navigate(["/orders/details/"+qr]).then(() => {
      setTimeout(()=>{    
        this.clearqr = '';
        this.onCloseHandledQR();
        this.searchElement.nativeElement.value = null;
      },2000); 
      window.location.reload();
    });

   
  }

  openModalQR() {
    this.displayQR = "block";
    this.qrpopupwindowshow = true;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    },0);

    //timer to wait for correct GUID from scanner ..until its good it clear and close and redirect
    this.interval = setInterval(()=>{      
      //console.log('It runs until its scanned'+this.searchElement.nativeElement.value)
      if (this.searchElement.nativeElement.value && this.searchElement.nativeElement.value.length==36){
        console.log('Ready to redirect to:'+this.searchElement.nativeElement.value);
        clearInterval(this.interval);
        this.onScanQR(this.searchElement.nativeElement.value)

      }
    },400)
  }
  
  onCloseHandledQR() {
    this.displayQR = "none";
    this.qrpopupwindowshow = false;
    clearInterval(this.interval);
  }

  // searchNewPatient(searchedName: string) {
  //   const apiUrl = `${APIMethodConfig.InservioAPIMethod.PatientManagement.NewPatientList}?SearchText=&name=${searchedName}&Email=&PatientId=&ContactNo=&State=&Programs=&SubGroup=&tags=&Status=&Gender=&Landline=&Address1=&Address2=&Suburb=&PostCode=&dobdatestart=&dobdateend=&CurrentPage=0&PageSize=100`;
  //   this.requestService.HttpGet<any>(apiUrl, null)
  //   .subscribe((response: any) => {
  //     if (response && response.entity && response.entity.length > 0) {
  //       this.toastr.success(`Successfully retrieved these records: ${response.totalRows}`, 'Success');
        
  //       this.patientsList = response.entity.map(patient => ({
  //         firstName: patient.firstName,
  //         lastName: patient.lastName,
  //         email: patient.emailAddress,
  //         phone: patient.phoneMobile,
  //         suburb: patient.suburb,
  //         postcode: patient.postcode,
  //         state: patient.state,
  //         dob: patient.dateOfBirth,   
  //         id: patient.patientUid,
  //         url: patient.patientId,
  //         program: patient.program
  
  //         // add any other details you'd like to display
  //       }));
  //     } else {
  //       this.toastr.error('0 Matches Found, try a different name', 'No Match');
  //     }
  //   },
  //   error => {
  //     this.toastr.error('There was a big error getting the results', 'Error');
  //   });
  // }


  @HostListener('window:keydown.F1', ['$event'])
  handleF1KeyPress(event: KeyboardEvent) {
    event.preventDefault(); // Prevent scrolling
    console.log("F1 was pressed")
    this.openModalOrderUID();
    // this.renderer.setStyle(this.quickSearchModalGlobal.nativeElement, 'display', 'block');
    // this.renderer.addClass(this.quickSearchModalGlobal.nativeElement, 'show');
    // this.isQuickSearchModalActive = true;

  }

//   closeModal() {
//     this.renderer.setStyle(this.quickSearchModalGlobal.nativeElement, 'display', 'none');
//     this.renderer.removeClass(this.quickSearchModalGlobal.nativeElement, 'show');
//     this.isQuickSearchModalActive = false;
// }

//   @HostListener('window:keydown.arrowup', ['$event'])
//   handleArrowUp(event: KeyboardEvent) {
//     if (this.isQuickSearchModalActive) {
//       event.preventDefault();
//       this.activatePreviousRow();
//   }
// }

// @HostListener('window:keydown.arrowdown', ['$event'])
// handleArrowDown(event: KeyboardEvent) {
//   if (this.isQuickSearchModalActive) {
//   event.preventDefault(); // Prevent scrolling
//   this.activateNextRow();
//   }
// }

// activatePreviousRow() {
//   if (this.activeRowIndex > 0) {
//     this.activeRowIndex--;
//     console.log('Active Row Index:', this.activeRowIndex);
//   }
// }

// activateNextRow() {
// //   if (this.activeRowIndex < this.patientsList.length - 1) {
// //     this.activeRowIndex++;
// //     console.log('Active Row Index:', this.activeRowIndex);
// //   }
// // }   
//  if (this.activeRowIndex < this.patientsList.length - 1) {
//   this.activeRowIndex++;
//   const activeItem = document.querySelector('.patient-list .list-group-item.active');
//   if (activeItem) {
//       activeItem.scrollIntoView({ behavior: 'smooth' });
//   }
// }
// }

// setActiveRow(index: number, id: number) {
//   this.activeRowIndex = index;
//   console.log('Active Row Index:', this.activeRowIndex);
//   console.log('Active Row ID:', id);
// }
// @HostListener('window:keydown.space', ['$event'])

// handleEnterKeyPress(event: KeyboardEvent) {
//   if (this.isQuickSearchModalActive) {
//     const activePatient = this.patientsList[this.activeRowIndex];
//     console.log(activePatient);
//     if (activePatient) {
//       this.quickSearchModalGlobal.nativeElement.click();
//       this.searchNameFormGlobal.form.reset();
//       this.searchNameFormGlobal.resetForm();
//       this.router.navigate(['/patients/edit/', activePatient.url]);
//       // window.location.reload();
//     }
//   }
// }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.onCloseHandled();
    this.reset();
  }

  stay() {
    this.onCloseHandled();
    this.reset();
  }

  logout() {
    this.onCloseHandled();
    this.idleService.setUserLoggedIn(false);
    this.router.navigate(['/']);
    this.subscription.unsubscribe();
    localStorage.clear();
    this.reset();
  }
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }

}

